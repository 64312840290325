<template>
  <div>
    <div class="az-content-breadcrumb">
      <span>
        <router-link :to="{ name: 'assets-home'}">Asset Registry</router-link>
      </span>
      <span>Catalog</span>
      <div class="mg-l-auto">
        <router-link :to="{ name: 'assets-home'}">
          <i class="fa fa-arrow-left" />
          Back
        </router-link>
      </div>
    </div>
    <h2 class="az-content-title">Catalog</h2>

    <div class="az-content-label mg-b-5">Catalogs</div>
    <p class="mg-b-20">An asset catalog is a collection of items owned by a person or company, regarded as having value, commitments, or legacies.</p>

    <div class="row row-sm mg-b-20">
      <div class="col-lg-3 offset-lg-9">
        <button class="btn btn-orange rounded w-100 font-weight-bold" data-target="#catalogNameModal" data-toggle="modal" title="Add Catalog">
          Add Catalog
        </button>
      </div>
    </div>

    <div class="table-responsive" v-if="catalogs.length > 0">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="tx-left w-5">ID</th>
            <th class="tx-left">Catalog</th>
            <th class="tx-left w-10 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="index" v-for="(catalog, index) in catalogs">
            <th>{{index + 1}}</th>
            <td>{{catalog.name}}</td>
            <td class="text-center">
              <router-link :to="{ name: 'assets', params: {  catalogid: catalog.catalog}}" class="mg-r-5 bg-white">
                <i class="fa fa-border fa-eye" title="View Assets" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mg-t-50" v-else>
      <h4 class="card-title text-muted text-center">No records found</h4>
    </div>

    <div class="modal" id="catalogNameModal">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-body">
            <div class="row row-sm form-group">
              <div class="col-md-12 col-lg-12">
                <label class="form-control-label">Catalog Name: <span class="tx-danger">*</span></label>
                <input class="form-control rounded" id="registry_name" name="registry_name" placeholder="Enter Name" required type="text" v-model="catalog.name">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="createCatalog" class="btn btn-indigo rounded" data-dismiss="modal" type="button">Submit</button>
            <button class="btn btn-light rounded" data-dismiss="modal" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Catalogs",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      catalogs: [],
      catalog: {
        name: null
      }
    };
  },
  mounted() {
    this.getCatalogs();
  },
  methods: {
    async getCatalogs() {
      this.catalogs = [];
      try {
        let reply = await this.$assetregistry.get(`/asset-registry/catalogs`);
        this.catalogs = reply.data ? reply.data.catalogs : [];
      } catch (e) {

      }
    },
    async createCatalog() {
      try {
        await this.$assetregistry.post(`/asset-registry/catalogs`, this.catalog);
        this.$toastr.s("Catalog Successfully created!", 'Success');
        this.getCatalogs();
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped>
</style>